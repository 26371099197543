html {
    min-height: 100vh
}

.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
    max-height: fit-content !important;
}

/* Allows the MUI grid rows to expand to child height */
.MuiDataGrid-cell {
    max-height: fit-content !important;
    overflow: auto;
    white-space: initial !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.risk-scores-class {
    border-bottom: 2px solid black;
}

.text-center {
    text-align: center;
}

.risk-button {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.risk-button > img {
    width: 400px;
}
.risk-button > h1 {
    position: relative;
    top: -50%;
    /*font-size: xxx-large;*/
    color: white;
    /*font-weight: bold;*/
}

